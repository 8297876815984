import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Button,
  Text,
  useColorModeValue,
  Heading,
  ModalHeader,
  ModalCloseButton,
  Stack,
  HStack,
} from "@chakra-ui/react";
import { COLORS } from "../../../constants/colors";
import { useContext, useEffect, useState, useCallback } from "react";
import { UserProfileContext } from "../../../context/userProfileContext";
import { AuthContext } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import { PROTECTED_PATHS } from "../../../app/constants";
import {
  getEstateDue,
  getEstateInfo,
  initializePayment,
  verifyEstateDuePayment,
} from "../../estateDue/service";
import {
  infoNotifier,
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import axiosInstance from "../../../services/api";

export const ConfirmPaymentModal = ({ dueTypes }) => {
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const btnBg = useColorModeValue(COLORS.lightGrey, COLORS.green);
  const btnColor = useColorModeValue(COLORS.green, COLORS.white);
  const [isLoading, setIsLoading] = useState(false);
  const { userProfile } = useContext(UserProfileContext);
  const { tenantName, email } = userProfile;
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [estateDue, setEstateDue] = useState([]);
  const [estateInfo, setEstateInfo] = useState([]);
  const [monthsAhead, setMonthsAhead] = useState(1);
  const [paymentData, setPaymentData] = useState({});
  const [paymentModal, setPaymentModal] = useState(false);
  const [transactionCharge, setTransactionCharge] = useState(0);
  const navigate = useNavigate();
  const [paymentLoading, setPaymentLoading] = useState(false);

  const { MY_WALLET } = PROTECTED_PATHS;

  // const getEstateDueAmount = estateInfo?.estateDue * monthsAhead;

  useEffect(() => {
    const fixedCharge = 100;
    const charges = 0.015 * dueTypes?.amount;
    if (dueTypes?.amount < 2000) {
      setTransactionCharge(charges);
      return;
    } else if (charges >= 2000) {
      setTransactionCharge(charges + fixedCharge);
      return;
    } else {
      setTransactionCharge(charges + fixedCharge);
    }
  }, [dueTypes?.amount]);

  useEffect(() => {
    getEstateDue(userId, setIsLoading, setEstateDue);
    getEstateInfo(userId, setIsLoading, setEstateInfo);
  }, [userId]);

  //PAYSTACK
  const payEstateDue = () => {
    const payload = {
      amount: dueTypes?.amount + transactionCharge,
      tenantName,
      email,
      userId,
      duration: monthsAhead,
      dueTypeId: dueTypes?._id,
    };

    console.log(payload, "payload");
    console.log(dueTypes, "duetypess");

    if (!payload.email) {
      infoNotifier("please set your profile and add your Email first ");
      return;
    }

    initializePayment(payload, setIsLoading, setPaymentData, setPaymentModal);
    // Call verifyPayments after initializing payment
    setPaymentModal(true); // Open modal here
  };

  const handlePayment = async () => {
    try {
      setPaymentLoading(true);
      const res = await axiosInstance.post("/route-pay/estate-due", {
        amount: dueTypes?.amount + transactionCharge,
        tenantName,
        email,
        userId,
        duration: monthsAhead,
        dueTypeId: dueTypes?._id,
      });

      const paymentInfo = res?.data;
      setPaymentData(paymentInfo);

      // Extract transaction reference
      const tnxRef = paymentInfo?.data?.transactionReference;
      if (!tnxRef) {
        throw new Error("Transaction reference missing.");
      }

      localStorage.setItem("Due-tnx", tnxRef);
      console.log("Transaction reference stored:", tnxRef);

      const redirectUrl = paymentInfo?.data?.redirectUrl;
      if (redirectUrl) {
        console.log("Redirecting to:", redirectUrl);
        window.location.href = redirectUrl;
      } else {
        console.warn("Redirect URL is missing.");
      }
    } catch (error) {
      console.error("Payment error:", error);
      errorNotifier("Payment failed. Please try again.");
      // setButtonDisabled(false); // Re-enable button on error
      throw error; // Rethrow to prevent further steps
    }
  };

  //PAYSTACK verifyPayments
  const verifyPayments = () => {
    const payload = {
      userId,
      reference: paymentData?.reference,
      type: "paystack",
    };

    verifyEstateDuePayment(payload, navigate);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const paymentChannel = "paystack"; //paystack or routepay

  return (
    <>
      <Button
        bg={btnBg}
        color={btnColor}
        onClick={onOpen}
        _hover={{ backgroundColor: `${btnBg}` }}
      >
        Pay Now
      </Button>
      <Modal
        size="sm"
        isOpen={isOpen}
        onClose={onClose}
        trapFocus={false}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent py="50px" bg="black">
          <ModalBody>
            <Heading fontSize="20px" color={cardBg} pb="30px">
              Choose Payment Method
            </Heading>
            <Text color={COLORS.lightGrey}>
              Kindly select your preferred payment method. You'll be receiving
              an acknowledgement via Email/SMS.
            </Text>
            {paymentChannel === "paystack" ? (
              <>
                <Button
                  w={"100%"}
                  mt="50px"
                  bg={bgColor}
                  color={cardBg}
                  _hover={{ backgroundColor: `${bgColor}` }}
                  isLoading={isLoading}
                  onClick={payEstateDue}
                >
                  Pay with Paystack
                </Button>
                <Modal
                  isOpen={paymentModal}
                  onClose={onClose} // Close modal without verification
                  isCentered
                  bg="red"
                  size="sm"
                  trapFocus={false}
                >
                  <ModalOverlay />
                  <ModalContent bg={"white"}>
                    <ModalHeader>Payment checkout</ModalHeader>
                    <ModalCloseButton onClick={verifyPayments} />{" "}
                    <Stack pb="4" pt="2" pl="7%" fontWeight="500">
                      <HStack fontWeight={600} fontSize="12px">
                        <Text pr="2">
                          {dueTypes?.type}: &#8358;{" "}
                          {dueTypes?.amount?.toLocaleString()}
                        </Text>
                        <Text>
                          Paystack Charge: &#8358;{" "}
                          {transactionCharge?.toLocaleString()}{" "}
                        </Text>
                      </HStack>
                      <Text fontWeight={600} fontSize="12px">
                        Total: &#8358;{" "}
                        {(
                          transactionCharge + dueTypes?.amount
                        )?.toLocaleString()}
                      </Text>
                    </Stack>
                    <ModalBody p="0">
                      <iframe
                        src={paymentData?.authorization_url}
                        width="100%"
                        height="450"
                        title="payment checkout"
                      ></iframe>
                    </ModalBody>
                  </ModalContent>
                </Modal>
              </>
            ) : (
              <Button
                w={"100%"}
                mt="50px"
                bg={bgColor}
                color={cardBg}
                _hover={{ backgroundColor: `${bgColor}` }}
                isLoading={isLoading} // Spinner while payment is processing
                isDisabled={paymentLoading} // Disable button when processing
                onClick={handlePayment}
              >
                {paymentLoading ? "Processing..." : "Pay with RoutePay"}
              </Button>
            )}
            {/* <Button
              w={"100%"}
              mt="20px"
              border={`1px solid ${cardBg}`}
              color={COLORS.primary}
            >
              Pay with bank transfer
            </Button> */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};